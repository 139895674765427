import { useEffect, useState } from 'react';

type Value<T> = T | WithInitialValue<T>;

interface WithInitialValue<T> {
  initial: T;
  next: T;
}

function isWithInitialValue(value: any): value is WithInitialValue<any> {
  return typeof value === 'object' && Object.hasOwn(value, 'initial');
}

function getValueDetails<T>(value: Value<T>): WithInitialValue<T> {
  if (isWithInitialValue(value)) {
    return value;
  }
  return {
    initial: value,
    next: value,
  };
}

// https://usehooks.com/useDebounce/
export const useDebounce = <T>(value: Value<T>, delay: number) => {
  const { next, initial } = getValueDetails(value);
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(initial);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(next);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [next, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};
